// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// store
import { getTeam } from "../../store/modules/team/services";
import { selectTeam } from "../../store/modules/team/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import AOS from "aos";

const Team = () => {
  // store
  const dispatch = useDispatch();
  const team = useSelector(selectTeam);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team.isSuccess]);

  const renderTeamate = (team) => {
    return team.data.map((item) => (
      <div key={item.id} className="team__card">
        <div className="team__card-inner">
          <div className="team__card-img">
            <img src={item.image} alt={item.full_name} />
          </div>
          <div className="team__card-title">{item.full_name}</div>
          <div className="team__card-note">{item.team_position}</div>
          <div
            className="team__card-desc"
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </div>
      </div>
    ))
  }
  // @ts-ignore
  return (
    <div className="team">
      <div className="app__wrapper">
        <div className="team__head">
          <div className="team__title">{t("pages.team.title")}</div>
          <div className="team__text"> {t("pages.team.text")}</div>
        </div>
        {team.isSuccess ? (
          <div className="team__grid">
            {team.data.length > 0 && renderTeamate(team)}
          </div>
        ) : (
          <PageLoader active={true} />
        )}
      </div>
    </div>
  );
};

export default Team;
