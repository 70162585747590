// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// store

// components
import AppButton from "../../../components/atoms/Button";
import Card from "../../../components/icons/Card";

// styles
import "./index.scss";

const WeldCTA = ({ data, variables }) => {
  // store

  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="weld-cta">
      <div className="app__wrapper">
        <div className="weld-cta__grid">
          <div className="weld-cta__content">
            <div className="weld__title weld__title--blue">{data.title}</div>
            {width > 768 && variables.weldmoney_link?.value && (
              <div className="weld-cta__actions">
                <AppButton
                  className="app-button--fill-3 weld-cta__btn"
                  href={variables.weldmoney_link.value}
                  prepend={<Card />}
                >
                  {t("actions.openCryptoCard")}
                </AppButton>
              </div>
            )}
          </div>
          <div className="weld-cta__img">
            <img src={data.image} alt="Rocket" />
          </div>
          {width <= 768 && variables.weldmoney_link?.value && (
            <div className="weld-cta__actions">
              <AppButton
                className="app-button--fill-3 weld-cta__btn"
                href={variables.weldmoney_link.value}
                prepend={<Card />}
              >
                {t("actions.openCryptoCard")}
              </AppButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldCTA;
