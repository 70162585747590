// core
import React from "react";
// store
import { useTranslation } from "react-i18next";

// components
import AppButton from "../../../components/atoms/Button";

// styles
import "./index.scss";

const WeldExamples = ({ data, referrals, variables }) => {
  const { t } = useTranslation();

  return (
    <div className="weld-examples">
      <div className="app__wrapper">
        <div className="weld-examples__head">
          <div className="weld__title">{data.title}</div>
          <div
            className="weld-examples__text"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </div>

        <div className="weld-examples__grid">
          {referrals.map((item, index) => (
            <div
              className="weld-examples__card"
              data-aos="fade-up"
              data-aos-offset="150"
              data-aos-delay={(index + 1) * 200}
            >
              <div className="weld-examples__card-imgs">
                {item.icons.map((imgItem) => (
                  <img src={imgItem.image} />
                ))}
              </div>
              <div className="weld-examples__card-title">{item.title}</div>
              <div className="weld-examples__card-num">0{index + 1}</div>
            </div>
          ))}
        </div>

        {variables.weldmoney_link?.value && (
          <div className="weld-examples__actions">
            <AppButton
              className="app-button--fill-3 weld-examples__btn"
              href={variables.weldmoney_link.value}
            >
              {t("actions.openCryptoCardShort")}
            </AppButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeldExamples;
