import app from "./modules/app/slice";
import generalData from "./modules/general-data/slice";
import blocks from "./modules/blocks/slice";
import contact from "./modules/contact/slice";
import variables from "./modules/variables/slice";
import team from "./modules/team/slice";
import news from "./modules/news/slice";
import ambassador from "./modules/ambassador/slice";
import weld from "./modules/weld/slice";
import trustWallet from "./modules/trust-wallet/slice";

const rootReducer = {
  app,
  generalData,
  blocks,
  contact,
  variables,
  team,
  news,
  ambassador,
  weld,
  trustWallet,
};

export default rootReducer;
