// core
import { Fragment, useMemo } from "react";
import { useWindowSize } from "react-use";
import Marquee from "react-fast-marquee";
// utils
import { MEDIA } from "../../../utils/constants";
// components

// styles
import "./index.scss";

const MarketServices = ({ generalData, className }) => {
  const { width } = useWindowSize();

  const Services = useMemo(() => {
    return (
      <div className="market-services__list">
        {generalData.map((item) => (
          <Fragment key={item.id}>
            {item.url ? (
              <a
                href={item.url}
                target="_blank"
                className="market-services__card"
              >
                <img src={item.image} alt={""} />
              </a>
            ) : (
              <span className="market-services__card">
                <img src={item.image} alt={""} />
              </span>
            )}
          </Fragment>
        ))}
      </div>
    );
  }, [generalData]);

  return (
    <div className={"market-services " + (className ? className : "")}>
      <div className="app__wrapper">
        <div className="market-services__box">
          {width > MEDIA.M768 ? Services : <Marquee>{Services}</Marquee>}
        </div>
      </div>
    </div>
  );
};

export default MarketServices;
