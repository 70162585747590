// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import {
  getWeldBlocks,
  getWeldContent,
} from "../../store/modules/weld/services";
import { getGeneralData } from "../../store/modules/general-data/services";
import { getBlocks } from "../../store/modules/blocks/services";
import {
  selectWeldBlocks,
  selectWeldContent,
} from "../../store/modules/weld/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
import { selectGeneralData } from "../../store/modules/general-data/selectors";
import { selectBlocks } from "../../store/modules/blocks/selectors";
// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import WeldExamples from "./WeldExamples";
import WeldCTA from "./WeldCTA";
import WeldFlow from "./WeldFlow";
import WeldBanner from "./WeldBanner";
import UsageSlides from "../../components/features/UsageSlides";
import AboutToken from "../../components/features/AboutToken";
import PricePool from "../../components/features/PricePool";

// styles
import "./index.scss";

import AOS from "aos";

const WellCard = () => {
  // store
  const dispatch = useDispatch();
  const weldBlocks = useSelector(selectWeldBlocks);
  const weldContent = useSelector(selectWeldContent);
  const variables = useSelector(selectVariables);
  const generalData = useSelector(selectGeneralData);
  const blocks = useSelector(selectBlocks);

  useEffect(() => {
    dispatch(getWeldBlocks());
    dispatch(getWeldContent());
    dispatch(getGeneralData());
    dispatch(getBlocks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      weldBlocks.isSuccess &&
      weldContent.isSuccess &&
      variables.isSuccess &&
      generalData.isSuccess &&
      blocks.isSuccess
    ) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weldBlocks.isSuccess,
    weldContent.isSuccess,
    variables.isSuccess,
    generalData.isSuccess,
    blocks.isSuccess,
  ]);

  // @ts-ignore
  return (
    <div className="weld">
      {weldBlocks.isSuccess &&
      weldContent.isSuccess &&
      variables.data &&
      generalData.isSuccess &&
      blocks.isSuccess ? (
        <>
          <div className="weld__oveflow">
            <WeldBanner data={weldBlocks.data.app} variables={variables.data} />

            {weldContent.data.referral_cards.length > 0 && (
              <WeldExamples
                data={weldBlocks.data.referral}
                referrals={weldContent.data.referral_cards}
                variables={variables.data}
              />
            )}

            <PricePool data={weldBlocks.data.cashbackBlock} />

            <WeldFlow data={weldBlocks.data.rate} variables={variables.data} />
          </div>

          <AboutToken
            className={"weld__about-token"}
            data={blocks.data.about_token}
            variables={variables.data}
          />
          <UsageSlides
            isCardPage={true}
            className={"weld__usage-slides"}
            generalData={generalData.data.usage_slides}
            variables={variables.data}
          />

          <WeldCTA
            data={weldBlocks.data.app_install}
            variables={variables.data}
          />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default WellCard;
