// core
import React from "react";
import { useTranslation } from "react-i18next";
// utils

// components

// styles
import "./index.scss";

const TrustWalletIcons = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="trust-wallet-icons">
      <div className="app__wrapper">
        <div className="trust-wallet-icons__box">
          <div className="trust-wallet-icons__title">
            {t("pages.trustWallet.reasons.title")}
          </div>
          <div className="trust-wallet-icons__grid">
            {data.map((item) => (
              <div key={item.id} className="trust-wallet-icons__card">
                <div className="trust-wallet-icons__card-icon">
                  <img src={item.icon} alt={item.title} />
                </div>
                <div className="trust-wallet-icons__card-title">
                  {item.title}
                </div>
                <div
                  className="trust-wallet-icons__card-text"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustWalletIcons;
