// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// store

// components
import AppButton from "../../../components/atoms/Button";
import WeldCTABg from "../../../components/icons/WeldCTABg";
import Card from "../../../components/icons/Card";

// styles
import "./index.scss";

// data

const HomeCTA = ({ data }) => {
  // store

  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="home-cta">
      <div className="app__wrapper">
        <div className="home-cta__grid">
          <div className="home-cta__content">
            <div className="home-cta__title">{data.title}</div>
            {data.text && <div className="home-cta__text">{data.text}</div>}
            {width > 768 && (
              <div className="home-cta__actions">
                <AppButton
                  className="app-button--fill-3 home-cta__btn"
                  to={"/weld-card"}
                  prepend={<Card />}
                >
                  {t("actions.openCryptoCard")}
                </AppButton>
              </div>
            )}
          </div>
          <div className="home-cta__img">
            <WeldCTABg />
            <img src={data.image} alt="Rocket" />
          </div>
          {width <= 768 && (
            <div className="home-cta__actions">
              <AppButton
                className="app-button--fill-3 home-cta__btn"
                to={"/weld-card"}
                prepend={<Card />}
              >
                {t("actions.openCryptoCard")}
              </AppButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeCTA;
