import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.trustWallet || initialState;

export const selectTrustWalletData = createSelector(
  [selectDomain],
  (trustWalletState) => trustWalletState.data
);
