// core

// utils

// components

// styles
import "./index.scss";

const PricePool = ({ data, className }) => {
  return (
    <div className={"price-pool " + (className ? className : "")}>
      <div className="app__wrapper">
        <div
          className="price-pool__title"
          dangerouslySetInnerHTML={{
            __html: data.title,
          }}
        />
        <div
          className="price-pool__desc"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />
        <div className="price-pool__grid">
          {data.image1 && (
            <div className="price-pool__card">
              <img src={data.image1} />
            </div>
          )}
          {data.image2 && (
            <div className="price-pool__card">
              <img src={data.image2} />
            </div>
          )}
        </div>
        <div
          className="price-pool__note"
          dangerouslySetInnerHTML={{
            __html: data.note,
          }}
        />
      </div>
    </div>
  );
};

export default PricePool;
