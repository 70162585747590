import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWeldBlocks = createAsyncThunk(
  `weld/getWeldBlocks`,
  async () => {
    const url = "weld/blocks";
    return await $apiClient.get(url);
  }
);

export const getWeldContent = createAsyncThunk(
  `weld/getWeldContent`,
  async () => {
    const url = "weld?include=icons";
    return await $apiClient.get(url);
  }
);
