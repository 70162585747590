// core
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";

// store
import { selectContactUsModal } from "../../../store/modules/app/selectors";
import { toggleContactUsModalStatus } from "../../../store/modules/app/slice";
import { selectVariables } from "../../../store/modules/variables/selectors";

// components
import Logo from "../../images/Logo";
import AppButton from "../Button";
import LangToggler from "../LangToggler";
import HeaderNav from "./HeaderNav";
import Menu from "../../icons/Menu";
import Sidebar from "./Sidebar";
import ContactUsModal from "../../features/ContactUsModal";
import TgIconBanner from "../../../assets/images/tgIconBanner.png";

// styles
import "./index.scss";

//data
import { languagesList } from "../../../locales/data";
import { MEDIA } from "../../../utils/constants";

// func
const Header = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const contactUsModalStatus = useSelector(selectContactUsModal);
  const variables = useSelector(selectVariables);

  const [isHomePage, setIsHomePage] = useState(false);
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [scrolled, setScrolled] = useState(
    "linear-gradient(90deg, #FED1BE 0%,#6DB3F8 10% ,#6DB3F8 100%)"
  );

  useEffect(() => {
    window.addEventListener("scroll", scrollProgressHandler);
    return () => {
      window.removeEventListener("scroll", scrollProgressHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let status =
      pathname === "/" ||
      languagesList.some((l) => {
        return pathname === `/${l.id}`;
      });

    setIsHomePage(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const scrollProgressHandler = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `linear-gradient(90deg, #FED1BE  ${
      (scrollPx / winHeightPx) * 100
    }%,#6DB3F8  ${(scrollPx / winHeightPx) * 100 + 10}%, #6DB3F8 105%)`;
    setScrolled(scrolled);
  };

  const onToggleSidebar = (flag) => {
    if (flag) {
      document.querySelector("body").classList.add("body--fixed");
    } else {
      document.querySelector("body").classList.remove("body--fixed");
    }
    setIsSidebarActive(flag);
  };

  const onOpenModal = () => {
    dispatch(toggleContactUsModalStatus(true));
  };

  const onCloseModal = () => {
    setTimeout(() => dispatch(toggleContactUsModalStatus(false)), 400);
  };

  return (
    <>
      <header className="header">
        {/* <div className="header__top">
          <div className="header__top-bar">
            <a href="https://loyaltyprogram.uahg.io" target="_blank">
              {t("nav.loyalty")}
            </a>
          </div>
        </div> */}

        <div className="header__top">
          <img src={TgIconBanner} className="header__top-icon" />
          <div className="header__top-bar">
            <a href={variables.data?.telegram_url?.value} target="_blank">
              {t("nav.subscribe.to.tg")}
            </a>
          </div>
          <img src={TgIconBanner} className="header__top-icon" />
        </div>

        {isHomePage && (
          <div className="header__progress">
            <div
              className="header__progress-bar"
              style={{ background: scrolled }}
            />
          </div>
        )}

        <div className="app__wrapper">
          <div className="header__content">
            <div className="header__logo">
              <RouterLink to={"/"}>
                <Logo />
              </RouterLink>
            </div>
            {width > MEDIA.M1280 && (
              <div className="header__nav">
                <HeaderNav />
              </div>
            )}

            <div className="header__actions">
              <LangToggler />
              {width > MEDIA.M1280 ? (
                <AppButton
                  className="header__btn app-button--fill"
                  onClick={() => onOpenModal()}
                >
                  {t("actions.contacts")}
                </AppButton>
              ) : (
                <AppButton
                  className={
                    "header__btn-menu app-button--flat" +
                    (isSidebarActive ? " header__btn-menu--active" : "")
                  }
                  onClick={() => onToggleSidebar(true)}
                >
                  <Menu />
                </AppButton>
              )}
            </div>
          </div>
        </div>
      </header>
      {width <= MEDIA.M1280 && (
        <Sidebar
          isActive={isSidebarActive}
          onClose={() => onToggleSidebar(false)}
          onOpenModal={() => onOpenModal()}
        />
      )}

      {contactUsModalStatus.isActive &&
        ReactDOM.createPortal(
          <ContactUsModal
            isActive={contactUsModalStatus.isActive}
            onClose={onCloseModal}
          />,
          document.getElementById("modal")
        )}
    </>
  );
};

export default Header;
