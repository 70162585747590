import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "trustWallet",
  initialState,
  extraReducers: {
    [services.getTrustWallet.pending]: (state) => {
      state.data.isLoading = true;
      state.data.isSuccess = false;
      state.data.error = null;
    },
    [services.getTrustWallet.rejected]: (state, action) => {
      state.data.isLoading = false;
      state.data.error = action.payload;
    },
    [services.getTrustWallet.fulfilled]: (state, action) => {
      state.data.isLoading = false;
      state.data.isSuccess = true;
      state.data.data = action?.payload?.data?.data;
    },
  },
});

export default slice.reducer;
