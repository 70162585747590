// core
import React from "react";
// utils

// components
import BannerBg from "../../../components/icons/BannerBg";

// styles
import "./index.scss";

const ReservesMain = ({ data }) => {
  return (
    <div className="reserves-main">
      <div className="app__wrapper">
        <div className="reserves-main__grid">
          <div
            className="reserves-main__bg"
            data-aos="zoom-in"
            data-aos-delay="600"
            data-aos-duration="3000"
            data-aos-easing="ease-out-quad"
          >
            <BannerBg />
          </div>

          <div className="reserves-main__content">
            <h1
              className="reserves-main__title"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />

            <div
              className="reserves-main__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div className="reserves-main__banner">
            <img
              src={data.image}
              alt={data.title}
              data-aos-once="true"
              data-aos-offset="0"
              data-aos="fade-up"
              data-aos-delay="300"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservesMain;
