// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

// store
import { getArticle } from "../../store/modules/news/services";
import { selectArticle } from "../../store/modules/news/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import NewsSlider from "../../components/atoms/NewsSlider";

const Article = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams();
  // store
  const dispatch = useDispatch();
  const article = useSelector(selectArticle);

  useEffect(() => {
    if (slug) {
      dispatch(getArticle(slug));
    } else {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  // useEffect(() => {
  //   if (article.isSuccess) {
  //     AOS.init({
  //       offset: 200,
  //       duration: 1000,
  //       easing: "ease-out-cubic",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [article.isSuccess]);
  // @ts-ignore
  return (
    <div className="article">
      <div className="app__wrapper">
        {article.isSuccess ? (
          <>
            {article.data && (
              <>
                <div className="article__wrapper">
                  <div className="article__img">
                    <img src={article.data.image} />
                  </div>
                  <div className="article__head">
                    <div className="article__title">{article.data.title}</div>
                    {article.data.date && (
                      <div className="article__date">
                        {moment(article.data.date).format("DD.MM.YYYY")}
                      </div>
                    )}
                  </div>
                  <div
                    className="article__content"
                    dangerouslySetInnerHTML={{
                      __html: article.data.description,
                    }}
                  />
                </div>
                {article.data.related?.data.length > 0 && (
                  <div className="article__related">
                    <NewsSlider
                      title={t("pages.article.sliderTitle")}
                      data={article.data.related.data}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <PageLoader active={true} />
        )}
      </div>
    </div>
  );
};

export default Article;
