// core
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// store
import {
  getAmbassador,
  sendAmbassadorForm,
} from "../../store/modules/ambassador/services";
import {
  selectAmbassador,
  selectAmbassadorForm,
} from "../../store/modules/ambassador/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import AppButton from "../../components/atoms/Button";
// styles
import "./index.scss";

import AOS from "aos";

// data
import { INITIAL_DATA } from "./data";

const Ambassador = () => {
  // store
  const dispatch = useDispatch();
  const ambassador = useSelector(selectAmbassador);
  const ambassadorForm = useSelector(selectAmbassadorForm);
  const variables = useSelector(selectVariables);

  const { t } = useTranslation();

  const [isTrySend, setIsTrySend] = useState(false);

  const formikRef = useRef();

  useEffect(() => {
    dispatch(getAmbassador());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ambassador.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ambassador.isSuccess]);

  useEffect(() => {
    if (!ambassadorForm.isLoading && isTrySend) {
      if (ambassadorForm.isSuccess) {
        toast.success(t("pages.ambassador.success"));
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
      } else {
        toast.error(t("pages.ambassador.error"));
      }
      setIsTrySend(false);
    }
  }, [ambassadorForm.isLoading]);

  const FormValidation = Yup.object().shape({
    full_name: Yup.string()
      .min(8, t("validation.min"))
      .max(191, t("validation.max"))
      .required(t("validation.required")),
    reason: Yup.string()
      .min(10, t("validation.min"))
      .max(5000, t("validation.max"))
      .required(t("validation.required")),
    experience: Yup.string()
      .min(10, t("validation.min"))
      .max(5000, t("validation.max"))
      .required(t("validation.required")),
    media: Yup.string()
      .min(10, t("validation.min"))
      .max(5000, t("validation.max"))
      .required(t("validation.required")),
    media_counts: Yup.string()
      .min(10, t("validation.min"))
      .max(5000, t("validation.max"))
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.isEmail"))
      .required(t("validation.required")),
  });

  const onSendHandler = (payload) => {
    setIsTrySend(true);
    dispatch(sendAmbassadorForm(payload));
  };

  // @ts-ignore
  return (
    <div className="ambassador">
      {ambassador.isSuccess ? (
        <>
          <div className="ambassador__head">
            <div className="app__wrapper">
              <div className="ambassador__head-grid">
                <div
                  className="ambassador__head-content"
                  data-aos="fade-up"
                  data-aos-delay="900"
                >
                  <div className="ambassador__head-title">
                    {ambassador.data.title}
                  </div>
                  <div
                    className="ambassador__head-text"
                    dangerouslySetInnerHTML={{
                      __html: ambassador.data.description,
                    }}
                  />
                </div>
                <div
                  className="ambassador__head-img"
                  data-aos="fade-up"
                  data-aos-delay="1400"
                >
                  <img src={ambassador.data.image} />
                </div>
              </div>
            </div>
          </div>
          <div className="ambassador__body">
            <div className="app__wrapper">
              <div className="ambassador__body-grid">
                <div
                  className="ambassador__form"
                  data-aos="fade-up"
                  data-aos-delay="1400"
                >
                  <div className="ambassador__form-title">
                    {t("pages.ambassador.inputFullNameLabel")}
                  </div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={INITIAL_DATA}
                    onSubmit={onSendHandler}
                    validationSchema={FormValidation}
                    innerRef={formikRef}
                  >
                    {({ errors, touched }) => (
                      <Form className="app-form">
                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {" "}
                            {t("pages.ambassador.inputFullNameLabel")}
                          </label>
                          <Field
                            type="text"
                            name="full_name"
                            placeholder={t(
                              "pages.ambassador.inputFullNamePlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.full_name &&
                              touched.full_name &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="full_name"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>
                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {" "}
                            {t("pages.ambassador.inputReasonLabel")}
                          </label>
                          <Field
                            type="text"
                            name="reason"
                            placeholder={t(
                              "pages.ambassador.inputReasonPlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.reason &&
                              touched.reason &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>

                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {" "}
                            {t("pages.ambassador.inputExperienceLabel")}
                          </label>
                          <Field
                            type="text"
                            name="experience"
                            placeholder={t(
                              "pages.ambassador.inputExperiencePlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.experience &&
                              touched.experience &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="experience"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>
                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {" "}
                            {t("pages.ambassador.inputMediaLabel")}
                          </label>
                          <Field
                            type="text"
                            name="media"
                            placeholder={t(
                              "pages.ambassador.inputMediaPlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.media &&
                              touched.media &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="media"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>

                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {t("pages.ambassador.inputMediaCountsLabel")}
                          </label>
                          <Field
                            type="text"
                            name="media_counts"
                            placeholder={t(
                              "pages.ambassador.inputMediaCountsPlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.media_counts &&
                              touched.media_counts &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="media_counts"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>

                        <div className="app-form__field app-form__field--input ambassador__form-field">
                          <label>
                            {" "}
                            {t("pages.ambassador.inputEmailLabel")}
                          </label>
                          <Field
                            type="text"
                            name="email"
                            placeholder={t(
                              "pages.ambassador.inputEmailPlaceholder"
                            )}
                            autoComplete="chrome-off"
                            className={
                              errors.email &&
                              touched.email &&
                              "app-form__field--error"
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="app-form__field-error"
                          />
                        </div>

                        <div className="ambassador__actions">
                          <AppButton
                            disabled={
                              ambassadorForm.isLoading ||
                              Object.keys(errors).length
                            }
                            type={"submit"}
                            loading={ambassadorForm.isLoading}
                            size={"big"}
                            className="ambassador__btn app-button--fill"
                          >
                            {t("actions.send")}
                          </AppButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div
                  className="ambassador__body-content"
                  data-aos="fade-up"
                  data-aos-delay="900"
                >
                  <div className="ambassador__body-title">
                    {ambassador.data.why_us_title}
                  </div>
                  <div
                    className="ambassador__body-text"
                    dangerouslySetInnerHTML={{
                      __html: ambassador.data.why_us_description,
                    }}
                  />
                  {ambassador.data.email && (
                    <a
                      href={`mailto:${ambassador.data.email}`}
                      className="ambassador__body-email"
                    >
                      {variables.data?.email_icon?.value && (
                        <img src={variables.data.email_icon.value} />
                      )}

                      {ambassador.data.email}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Ambassador;
