// core
import { useTranslation } from "react-i18next";

// utils
import { copyToClipboard } from "../../../utils/common";

// components
import Copy from "../../icons/Copy";

// styles
import "./index.scss";

const NetContracts = ({ generalData, className }) => {
  const { t } = useTranslation();

  const onCopyHandler = (data) => {
    copyToClipboard(data);
  };

  return (
    <div
      className={"net-contracts " + (className ? className : "")}
      id="contracts"
    >
      <div className="app__wrapper">
        <div className="net-contracts__grid">
          <div className="net-contracts__title">
            {" "}
            {t("blocks.netContracts.title")}
          </div>
          <ul className="net-contracts__list">
            {generalData.map((item, index) => (
              <li
                data-aos="fade-up"
                data-aos-offset="150"
                data-aos-delay={(index + 1) * 200}
                key={item.id}
                className="net-contracts__card"
              >
                <div className="net-contracts__card-icon">
                  <img src={item.icon} alt={item.name} />
                </div>
                <div className="net-contracts__card-name">{item.title}</div>
                <div className="net-contracts__card-content">
                  <a href={item.url} target="_blank" title={item.address}>
                    {item.address}
                  </a>
                  <div
                    className="net-contracts__card-btn"
                    onClick={() => onCopyHandler(item.address)}
                  >
                    <Copy />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NetContracts;
