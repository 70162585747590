// core
import { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import Expander from "../../../components/atoms/Expander";

// styles
import "./index.scss";
import ArrowUpRight from "../../../components/icons/ArrowUpRight";

const HomeFaqs = ({ data, generalData }) => {
  const { t } = useTranslation();
  const [expanderId, setExpanderId] = useState(null);

  const onClickHandler = (id) => {
    setExpanderId(expanderId !== id ? id : null);
  };

  return (
    <div className="home-faqs" id="faq">
      <div className="app__wrapper">
        <div className="home-faqs__grid">
          <div className="home-faqs__aside">
            <div className="home-faqs__title">{data.title}</div>
            <div
              className="home-faqs__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <a
              href={data.url}
              className="home-faqs__link"
              target="_blank"
              title={t("pages.home.faq.linkTitle")}
            >
              {t("faq.linkTitle")}
              <ArrowUpRight />
            </a>
          </div>
          <div className="home-faqs__content">
            <div className="home-faqs__expander">
              {generalData.map((item, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-offset="150"
                  data-aos-delay={(index + 1) * 200}
                  className="home-faqs__expander-item"
                  key={index}
                  id={`item-${index}`}
                >
                  <Expander
                    title={item.question}
                    active={expanderId === index}
                    onClick={() => onClickHandler(index)}
                  >
                    <div
                      className="home-faqs__expander-content"
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  </Expander>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFaqs;
