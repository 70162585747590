import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTrustWallet = createAsyncThunk(
  `trustWallet/getTrustWallet`,
  async () => {
    const url = "trust_wallet?include=cards";
    return await $apiClient.get(url);
  }
);
