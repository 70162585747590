// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// utils

// components
import HomeMainBgImg from "../../../assets/images/home-main-bg.png";
import AppButton from "../../../components/atoms/Button";

// styles
import "./index.scss";

// data

const HomeMain = ({ data }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="home-main">
      <img src={HomeMainBgImg} className="home-main__bg" />
      <div className="app__wrapper">
        <div className="home-main__grid">
          <div
            className="home-main__info"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="home-main__title">{data.title}</div>
            {data.description && (
              <div className="home-main__desc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />
              </div>
            )}
            {width > 768 && (
              <>
                <div className="home-main__actions">
                  <AppButton
                    className="app-button--fill-3 home-main__btn"
                    to={"/weld-card"}
                  >
                    {t("actions.openCryptoCard")}
                  </AppButton>
                  <div className="home-main__note">{data.left_title}</div>
                  <div className="home-main__note">{data.right_title}</div>
                </div>
              </>
            )}
          </div>
          <div className="home-main__banner">
            <img src={data.image} />
          </div>
          {width <= 768 && (
            <>
              <div
                className="home-main__actions"
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="300"
              >
                <AppButton
                  className="app-button--fill-3 home-main__btn"
                  to={"/weld-card"}
                >
                  {t("actions.openCryptoCard")}
                </AppButton>

                <div className="home-main__note">{data.left_title}</div>
                <div className="home-main__note">{data.right_title}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
