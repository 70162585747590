// core
import React from "react";
import { useTranslation } from "react-i18next";

// components
import ComingSoon1Img from "../../assets/images/coming-soon-1.png";
import ComingSoon2Img from "../../assets/images/coming-soon-2.png";

// styles
import "./index.scss";

const ComingSoon = () => {
  const { t } = useTranslation();

  // @ts-ignore
  return (
    <div className="coming-soon">
      <div className="app__wrapper">
        <div className="coming-soon__box">
          <img src={ComingSoon1Img} className="coming-soon__box-icon-1" />
          <img src={ComingSoon2Img} className="coming-soon__box-icon-2" />
          <div className="coming-soon__title">
            {" "}
            {t("pages.comingSoon.title")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
